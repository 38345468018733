const circleToPolygon = require('circle-to-polygon')

const state = {
  marker: {
    latLng: {
      lat: 0,
      lng: 0
    },
    radius: 0
  },
  // represented as a GeoJSON
  geometry: {
    coordinates: [],
    type: ""
  }
}

const getters = {
  markerCoordinates: state => state.marker.latLng
}

const actions = {
  addMarker ({ commit }, { latLng, radius }) {
    commit('setMarker', { latLng, radius })

    // circleToPolygon return geoJson with 'type' & 'coordinates' attributes
    const geoJson = circleToPolygon([latLng.lng, latLng.lat], radius, 32)
    commit('setGeometry', geoJson)
  }
}

const mutations = {
  setMarker (state, marker) {
    state.marker = marker
  },
  setGeometry (state, coordinates) {
    state.geometry = coordinates
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
