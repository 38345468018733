import ApiService from '@/plugins/api.service'

const initialState = {
  // geoJson
  selectedArea: {},
  selectedColors: [],
  selectedPattern: [],
  selectedSize: '',
  isLoading: false,
  results: [],
  resultCount: 0,
  apiMessage: '',
}

const state = {
  ...initialState
}

const getters = {
  state: state => state,
}

const actions = {
  async search ({ commit }, {
    colors,
    pattern,
    geometry,
    size
  }) {
    commit('setSelectedColors', colors)
    commit('setSelectedPattern', pattern)
    commit('setSelectedSize', size)
    commit('setSelectedArea', geometry)

    await ApiService.post('id', {
      colors,
      pattern,
      geometry,
      size
    }).then(response => {
      commit('setResponse', response.data)
    })
  },
}

const mutations = {
  setSelectedArea (state, polygon) {
    state.area = polygon
  },
  setSelectedColors (state, colors) {
    state.colors = colors
  },
  setSelectedPattern (state, pattern) {
    state.pattern = pattern
  },
  setSelectedSize (state, size) {
    state.size = size
  },
  setResponse (state, response) {
    state.results = response.results
    state.resultCount = response.resultCount
    state.apiMessage = response.message
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
