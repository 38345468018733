<template>
  <b-navbar id="navbar" toggleable="lg" type="light">
    <b-navbar-brand id="navbar-brand" to="/">
      THE <br>
      REPTILE <br>
      DATABASE
    </b-navbar-brand>
    <b-navbar-toggle target="navbar-toggle-collapse"></b-navbar-toggle>

    <b-collapse id="navbar-toggle-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :to="{name: 'Landing'}" class="underline-scaled-black">Search</b-nav-item>
        <b-nav-item :to="{name: 'Identification'}" class="underline-scaled-black">Identification</b-nav-item>
        <div>
          <a>
            <b-nav-item-dropdown text="About">
              <b-dropdown-item :to="{name: 'General'}">General information and FAQ</b-dropdown-item>
              <b-dropdown-item :to="{name: 'News'}">News</b-dropdown-item>
              <b-dropdown-item :to="{name: 'Statistics'}">Statistics</b-dropdown-item>
              <b-dropdown-item :to="{name: 'Editors'}">Editors</b-dropdown-item>
              <b-dropdown-item :to="{name: 'Sab'}">SAB</b-dropdown-item>
              <b-dropdown-item :to="{name: 'Credits'}">Credits</b-dropdown-item>
            </b-nav-item-dropdown>
            <font-awesome-icon icon="chevron-down"></font-awesome-icon>
          </a>
        </div>
        <b-nav-item :to="{name: 'Publications'}" class="underline-scaled-black">Publications</b-nav-item>
        <div>
          <a>
            <b-nav-item-dropdown text="Data">
              <b-dropdown-item :to="{name: 'Downloads'}">Downloads</b-dropdown-item>
              <b-dropdown-item :to="{name: 'Sources'}">Data Sources</b-dropdown-item>
            </b-nav-item-dropdown>
            <font-awesome-icon icon="chevron-down"></font-awesome-icon>
          </a>
        </div>
      </b-navbar-nav>

      <!--
      <b-navbar-nav class="ml-auto">
        <b-nav-form @submit="search">
          <b-input-group id="navbar-search">
            <b-form-input
              id="navbar-search-input"
              size="md"
              placeholder="Search for taxa"
              v-model="searchValue"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button size="md" type="submit" variant="outline-white">
                <font-awesome-icon icon="search"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-nav-form>
      </b-navbar-nav>
      -->
    </b-collapse>
  </b-navbar>
</template>

<script>
//TODO: Dropdown animations(.dropdown-menu slide down, chevron down -> up)
import router from '../router/router'

export default {
  name: 'main-nav',
  data () {
    return {
      searchValue: ''
    }
  },
  methods: {
    search (evt) {
      evt.preventDefault()

      if (this.searchValue.length > 0) {
        let searchQuery = '/search?search=' + this.searchValue
        this.$store.dispatch('search/saveType', 'text')
        this.$store.dispatch('search/fetchTextResults', searchQuery)
        router.push(searchQuery)
      }
    }
  }
}
</script>

<style lang="scss">
#navbar {
  padding: 3em 6em 0 4em;
  font-weight: 500;

  @media screen and (max-width: $bp-large) {
    padding: 2em 3em 0 2em;
  }

  #navbar-toggle-collapse {
    justify-content: flex-end;
    text-transform: uppercase;

    @media screen and (max-width: $bp-large) {
      width: 100%;
      padding: 2em 3em;
      background-color: $grey-background;
      z-index: 9999;
      border-radius: 10px;
    }

    div {
      @media screen and (min-width: $bp-large) {
        cursor: pointer;
        margin-bottom: -10px;

        &:hover {
          a {
            .dropdown-menu {
              display: block;
            }
          }
        }
      }
    }

    svg {
      color: $grey-2;
      width: 10px;
      transform: scale(1.5, 1);
      margin-left: 10px;
      display: none;

      @include desktop-large-up {
        display: inline-block;
      }
    }

    .navbar-nav {
      width: 800px;
      justify-content: space-around;
    }

    .nav-link {
      padding: 0;
      font-size: 1.25em;
      color: $black;

      /*remove bs-dropdown chevron*/
      &::after {
        display: none;
      }
    }
  }

  .dropdown:hover > .dropdown-menu {
    @media screen and (min-width: $bp-large) {
      display: block;
    }
  }

  .dropdown {
    display: inline-block;
  }

  .dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
  }

  .dropdown-menu {
    left: -18px;
    top: 40px;
    padding: 1em 0;
    margin: 0;
    display: block;
    border: none;
    background-color: $grey-background;

    @include desktop-large-up {
      display: none;
      border: 1px solid $grey-disabled;
      box-shadow: 4px 10px 15px 0 $grey-disabled;
      background-color: white;
    }

    li {
      z-index: 99;
    }

    a {
      padding: 0.5rem 1.5rem;
    }
  }
}

/*
  #navbar-search {
    input {
      background-color: transparent;
      border-top: none;
      border-right: none;
      border-left: none;

      &:focus {
        border-right-color: transparent;
        border-left-color: transparent;
        border-top-color: transparent;
        border-bottom-color: $color-secondary;
        box-shadow: 0 4px 2px -2px $color-secondary;
      }
    }
  }
*/
</style>
