<template>
  <div id="app">
    <error></error>
    <main-nav></main-nav>
    <div class="app-content">
      <keep-alive :exclude="['Landing', 'search-detail']">
        <router-view></router-view>
      </keep-alive>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
import MainNav from '@/components/MainNav.vue'
import MainFooter from './components/Footer'
import Error from '@/components/base/Error'

export default {
  name: 'App',
  components: {
    MainFooter,
    MainNav,
    Error
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        document.title = to.meta.title || 'The Reptile Database'
      }
    },
  }
}
</script>
<style lang="scss" type="scss">
#app {
  display: flex;
  //gives other children the possibility to expand
  min-height: 100vh;
  flex-direction: column;

  .app-content {
    margin-top: 2.5em;
    //expands content part
    flex-grow: 1;
  }
}
</style>
