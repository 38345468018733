import Vue from "vue"
import App from "./App.vue"
import Router from "./router/router"
import Store from "./store/index"
import VueApollo from "vue-apollo"
import ApolloClient from "apollo-boost"

import "./scss/base.scss"

// TODO: import single components instead of plugins(all components)
import {
  AlertPlugin,
  BIcon,
  BIconChevronLeft,
  BIconChevronRight,
  BIconSliders,
  BIconX,
  BIconXCircle,
  BreadcrumbPlugin,
  ButtonPlugin,
  CarouselPlugin,
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  InputGroupPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  NavbarPlugin,
  NavPlugin,
  PaginationPlugin,
  PopoverPlugin,
  TablePlugin,
  TabsPlugin,
  TooltipPlugin
} from "bootstrap-vue"
import { LMap, LTileLayer } from "vue2-leaflet"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPaperclip,
  faSearch,
  faSortDown,
  faSortUp
} from "@fortawesome/free-solid-svg-icons"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import { faWikipediaW } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

Vue.component("BIcon", BIcon)
Vue.component("BIconX", BIconX)
Vue.component("BIconXCircle", BIconXCircle)
Vue.component("BIconChevronRight", BIconChevronRight)
Vue.component("BIconChevronLeft", BIconChevronLeft)
Vue.component("BIconSliders", BIconSliders)

Vue.use(ButtonPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(AlertPlugin)
Vue.use(PopoverPlugin)
Vue.use(LinkPlugin)
Vue.use(BreadcrumbPlugin)
Vue.use(TablePlugin)
Vue.use(LayoutPlugin)
Vue.use(FormSelectPlugin)
Vue.use(PaginationPlugin)
Vue.use(CarouselPlugin)
Vue.use(FormRadioPlugin)
Vue.use(TabsPlugin)
Vue.use(ListGroupPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(TooltipPlugin)

Vue.use(LMap, LTileLayer)

library.add(
  faChevronRight,
  faChevronDown,
  faSortUp,
  faSortDown,
  faChevronLeft,
  faSearch,
  faPaperclip,
  faQuestionCircle,
  faArrowCircleRight,
  faArrowCircleLeft,
  faWikipediaW
)
Vue.component("font-awesome-icon", FontAwesomeIcon)

library.add(
  faChevronRight,
  faChevronDown,
  faSortUp,
  faSortDown,
  faChevronLeft,
  faSearch,
  faPaperclip,
  faQuestionCircle,
  faArrowCircleRight,
  faArrowCircleLeft,
  faWikipediaW
)
Vue.component("font-awesome-icon", FontAwesomeIcon)

Vue.use(VueApollo)
const apolloClient = new ApolloClient({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_URL
})
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

new Vue({
  router: Router,
  store: Store,
  apolloProvider,
  render: (h) => h(App)
}).$mount("#app")
