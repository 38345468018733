const state = {
  errors: []
}

const getters = {
  errors: (state, commit) => commit('popError')
}

const actions = {
  pushError: ({ commit }, error) => {
    commit('addError', error)
    // setTimeout(() => commit('popError'), 3000);
  }
}

const mutations = {
  addError: (state, error) => state.errors.unshift(error),
  popError: (state) => state.errors.pop()
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
