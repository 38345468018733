import axios from 'axios'

const AXIOS = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

const ApiService = {
  query (queryString) {
    return AXIOS.get(queryString)
  },
  post (queryString, data) {
    return AXIOS.post(queryString, data).catch(err => {
      console.log(err)
    })
  }
}

export default ApiService
