import ApiService from '@/plugins/api.service'
import rewind from 'geojson-rewind'

const state = () => ({
  ...initialState
})

const initialState = {
  taxa: {},
  spatial: {},
  bibliography: [],
  imgFileNames: [],
  title: '',
  isLoading: true
}

const getters = {}

const actions = {
  async getTaxa ({ commit }, queryString) {
    commit('fetchStart')
    await ApiService.query(queryString).then(response => {
      let taxa = response.data
      commit('setTaxa', taxa)
      commit('setTitle', `${taxa.genus} ${taxa.species} (${taxa.author}, ${taxa.year})`)
      commit('fetchEnd')
    })
  },

  async getSpatial ({ commit }, {
    genus,
    species
  }) {
    return await ApiService.query(`/spatial?genus=${genus}&species=${species}`).then(resp => {
      if (!resp.data.geometry) return null
      let geometry = resp.data.geometry
      //CamelCase GeoJSON "type" property to make GeoJSON valid
      if (geometry.type.indexOf('multi') === 0) {
        geometry.type = 'MultiPolygon'
      } else if (geometry.type.indexOf('polygon') === 0) geometry.type = 'Polygon'

      //Ensure right-hand rule (area orientation)
      resp.data.geometry = rewind(geometry, true)
      return resp.data
    })
  },

  async getImages ({ commit }, {
    genus,
    species
  }) {
    await ApiService.query(`/search/img?genus=${genus}&species=${species}`).then(resp => {
      if (resp.data.hasOwnProperty('file_names')) {
        commit('setImages', resp.data.file_names)
      } else {
        commit('setImages', [])
      }
    })
  },

  async getBibliography ({ commit }, referenceNumbers) {
    await ApiService.query(`/bibliography?ids=${referenceNumbers}`).then(resp => {
      commit('setBibliography', resp.data)
    })
  }
}

const mutations = {
  fetchStart (state) {
    state.isLoading = true
  },
  fetchEnd (state) {
    state.isLoading = false
  },
  setTaxa (state, taxa) {
    state.taxa = taxa
  },
  setImages (state, fileNames) {
    state.imgFileNames = fileNames
  },
  setBibliography (state, bibliography) {
    state.bibliography = bibliography
  },
  setTitle (state, title) {
    state.title = title
  },
  reset (state) {
    for (let key in state) {
      if (state.hasOwnProperty(key)) {
        state[key] = initialState[key]
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
