import Vue from "vue"
import Vuex from "vuex"
import search from "./modules/search.js"
import error from "./modules/error.js"
import identification from "./modules/identification.js"
import spatialMap from "./modules/spatialMap.js"
import species from "./modules/species.js"

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    search,
    species,
    error,
    identification,
    spatialMap
  }
})
