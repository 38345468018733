<template>
  <div id="footer">
    <div class="footer-content">
      <!--
      <div class="newsletter">
        <div class="form-group">
          <label for="newsletterEmailInput">Mailing list</label>
          <div class="input-group mb-3">
            <input type="text" id="newsletterEmailInput" class="form-control" placeholder="Your Email" aria-label="Your Email adress"
                   aria-describedby="newsletterHelp">
            <div class="input-group-append">
              <button class="btn btn-outline-success" type="button">Subscribe!</button>
            </div>
          </div>
          <small id="newsletterHelp" class="form-text text-muted">We'll never share your email with anyone else!</small>
        </div>
      </div>
      -->
    </div>
    <div class="credits">
      <b-row>
        <b-col cols="9">
          Copyright © 1995-2018 by Peter Uetz.
          This database is maintained by Peter Uetz with help from many volunteers. <br>
          You may cite this database as Uetz, P., Freed, P., The Reptile Database, http://www.reptile-database.org,
          accessed [insert date here] (more...)
        </b-col>
        <b-col cols="3" class="align-self-end text-right">
          <router-link :to="{ name: 'Legal-disclosure' }">Legal disclosure</router-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'main-footer'
}
</script>

<style lang="scss" type="scss">
#footer {
  width: 100%;
  color: white;
  border-top: 1px solid lightgrey;
  background-color: $grey-dark;

  .footer-content {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin: 0 10% 0 10%;

    > div {
      padding: 1em;
    }

    .newsletter {
      align-self: center;
      min-width: 50%;
      flex: 1;

      label {
        width: 100%;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .form-group {
        .mb-3 {
          margin-bottom: 0.5rem !important;
        }
      }
    }
  }

  .credits {
    background-color: $grey-1;
    padding: 20px 5% 20px 5%;
    font-size: 0.8em;
  }
}
</style>
