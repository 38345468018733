import ApiService from '@/plugins/api.service'
import Store from '@/store'
import router from '@/router/router'

const initialState = {
  results: [],
  totalResults: 0,
  totalPages: 0,
  //"real" page, starting with 0, from server
  currentPage: 0,
  selectedId: '',
  query: '',
  type: '',
  isLoading: false,
  hasResults: false
}

let state = { ...initialState }

const actions = {
  async fetchTextResults ({
                            commit,
                            dispatch
                          }, {
                            query,
                            page = 0,
                            size = 20
                          }) {
    commit('fetchStart')
    commit('setQuery', query)
    //TODO: add state for failed response
    return ApiService.query(`search?${query}&page=${page}&size=${size}`).then(response => {
      if (response.data) {
        commit('setResult', response.data)
        return response.data
      }
    }).catch(e => {
      Store.dispatch('error/pushError', e, { root: true })
      router.replace('/')
    }).finally(() => commit('fetchEnd'))
  },
  async fetchSpatialResults ({
                               commit,
                               rootState
                             }, {
                               page = 0,
                               size = 20
                             }) {
    console.log('fetchSpatialResults')
    commit('fetchStart')
    return ApiService.post(`search?page=${page}&size=${size}`, rootState.spatialMap.geometry).then(response => {
      commit('setResult', response.data)
      commit('fetchEnd')
      return response.data
    })
  },
  resetResults ({ commit }) {
    commit('clearResults')
  },
  saveSelectedId ({ commit }, item) {
    commit('setSelectedId', item)
  },
  saveType ({ commit }, type) {
    commit('setType', type)
  }
}

const mutations = {
  fetchStart (state) {
    state.isLoading = true
  },
  fetchEnd (state) {
    state.isLoading = false
  },
  setQuery (state, queryString) {
    state.query = queryString
  },
  setSelectedId (state, id) {
    state.selectedId = id
  },
  setType (state, type) {
    state.type = type
  },
  setResult (state, responseData) {
    state.results = [...responseData.content]
    state.totalResults = responseData.totalElements
    state.totalPages = responseData.totalPages
    state.currentPage = responseData.pageable.pageNumber
  },
  clearResults (state) {
    state.results = []
    Object.assign(state, initialState)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
