import Vue from "vue"
import Router from "vue-router"
import store from "../store/index"

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "Landing",
      component: () => import("@/views/Landing"),
      meta: {
        title: "Search the Reptile Database"
      }
    },
    {
      path: "/search",
      name: "Results",
      component: () => import("@/components/search/SearchResults"),
      meta: {
        breadcrumb: "Results",
        query: true,
        title: "Your Search results"
      }
    },
    {
      path: "/species",
      name: "Detail",
      component: () => import("@/components/search/SearchDetail"),
      meta: {
        breadcrumb: "",
        query: true,
        title: "Species lookup"
      }
    },
    {
      path: "/id",
      name: "Identification",
      component: () => import("@/components/id/Identification"),
      meta: {
        title: "Identify reptile species"
      }
    },
    {
      path: "/taxa",
      name: "Taxa",
      component: () => import("@/views/taxa/Taxa"),
      children: [
        {
          path: "lizards",
          name: "Lizards",
          meta: {
            title: "Taxonomic information on lizards",
            toTop: false
          }
        },
        {
          path: "snakes",
          name: "Snakes",
          meta: {
            title: "Taxonomic information on snakes",
            toTop: false
          }
        },
        {
          path: "turtles",
          name: "Turtles",
          meta: {
            title: "Taxonomic information on turtles",
            toTop: false
          }
        },
        {
          path: "amphisbaenia",
          name: "Amphisbaenia",
          meta: {
            title: "Taxonomic information on amphisbaenians",
            toTop: false
          }
        },
        {
          path: "crocodiles",
          name: "Crocodiles",
          meta: {
            title: "Taxonomic information on crocodiles",
            toTop: false
          }
        },
        {
          path: "tuataras",
          name: "Tuataras",
          meta: {
            title: "Taxonomic information on tuataras",
            toTop: false
          }
        }
      ]
    },
    {
      path: "/about",
      component: () => import("@/views/about/About"),
      children: [
        {
          path: "",
          name: "General",
          component: () => import("@/views/about/General"),
          meta: {
            title: "General information and FAQs"
          }
        },
        {
          path: "news",
          name: "News",
          component: () => import("@/views/about/News"),
          meta: {
            title: "Latest updates"
          }
        },
        {
          path: "stats",
          name: "Statistics",
          component: () => import("@/views/about/Statistics"),
          meta: {
            title: "Statistics for the Reptile Database"
          }
        },
        {
          path: "editors",
          name: "Editors",
          component: () => import("@/views/about/Editors"),
          meta: {
            title: "Editors which contributed to the Reptile Database"
          }
        },
        {
          path: "sab",
          name: "Sab",
          component: () => import("@/views/about/Sab"),
          meta: {
            title: "All people contributing to the Reptile Database"
          }
        },
        {
          path: "credits",
          name: "Credits",
          component: () => import("@/views/about/Credits"),
          meta: {
            title: "Reptile Database contributers"
          }
        }
      ]
    },
    {
      path: "/publications",
      name: "Publications",
      component: () => import("@/views/Publications"),
      meta: {
        title: "Journals and other resources used from the Reptile Database"
      }
    },
    {
      path: "/data",
      name: "Downloads",
      component: () => import("@/views/data/Downloads"),
      meta: {
        title: "Download our datasets"
      }
    },
    {
      path: "/sources",
      name: "Sources",
      component: () => import("@/views/data/Sources.vue"),
      meta: {
        title: "Our data sources and references"
      }
    },

    {
      path: "/legal-disclosure",
      name: "Legal-disclosure",
      component: () => import("@/views/footer/Legal"),
      meta: {
        title: "Legal disclosure"
      }
    },
    {
      path: "*",
      redirect: "/"
    }
  ]
})

// redirect to Search-Form if no query string (for search/species) is specified
router.beforeEach((to, from, next) => {
  if (to.meta.toTop == null || to.meta.toTop !== false) {
    window.scrollTo(0, 0)
  }
  if (to.meta.query && store.state.search.type !== "Spatial") {
    const query = to.query
    const exists = Object.keys(query).filter(function (key) {
      return query[key] !== null && query[key] !== ""
    })
    if (
      (to.meta.query && exists.length < Object.keys(query).length) ||
      exists < 1
    ) {
      // const error = {
      //   to: to.name,
      //   type: 'query',
      //   message: 'Please provide a valid query in the URL.'
      // }
      // store.dispatch('error/populateErrors', error)
      // next({
      //   name: 'Landing',
      //   replace: true
      // })
    }
  }
  next()
})

export default router
