<template>
  <div>
    <b-alert v-for="(error, index) in errors" :key="error.message.substr(0,5) + index" :show="dismissCountDown"
             dismissible fade variant="danger"
             @dismiss-count-down="countDownChanged">
      {{ error.message }}
      <br>
    </b-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'error',
  data () {
    return {
      dismissSecs: 5,
      dismissCountDown: 5,
      displayed: true
    }
  },
  computed: {
    ...mapState('error', ['errors'])
  },
  methods: {
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
  }
}
</script>
